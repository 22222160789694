import "./index.css";
import PDFResume from "../../assets/files/VarunSenguttuvan-Resume.pdf";

import React from "react";

const Resume = () => {
  return (
    <>
      <div className="container" id="resume">
        {/*Start of Heading*/}
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <h3>Varun Senguttuvan</h3>
            varunseng.com&nbsp;|&nbsp;varun.senguttuvan@gmail.com
          </div>
          <div
            className="col-lg-3 col-md-12 text-lg-end text-sm-start"
            style={{ marginTop: "20px" }}
          >
            <a
              className="btn btn-default btn-sm"
              href={PDFResume}
              role="button"
              style={{ backgroundColor: "#f0f0f0" }}
            >
              View as PDF
            </a>
          </div>
        </div>
        <hr />
        {/*End of Heading*/}

        {/* START OF Professional Experience */}
        <h4>Professional Experience</h4>
        {/* Hashicorp */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Senior Software Engineer</b> at HASHICORP
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Aug 2022 - Present, Toronto</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                As part of the Platform Identity team, helped build the
                Hashicorp Cloud Platform (HCP) Identity service including SAML
                and OIDC support
              </li>
              <li>
                Helped add Disaster Recovery Infrastructure to identity services
                ensuring seemless failover between AWS regions
              </li>
              <li>
                Led efforts to add Continuous Verification testing to Hashicorp
                Cloud Platform. Enables the testing of identity services running
                in a production environment on a regular cadence to alert in
                case of any issues and measure uptime of individual endpoints
              </li>
              <li>
                Skills: Golang, Terraform, Vault, Nomad, Python, Typescript,
                Ember, Playwright
              </li>
            </ul>
          </div>
        </div>

        {/* Instacart */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Infrastructure Engineer</b> at INSTACART
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Aug 2021 - Aug 2022, Toronto</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                As part of the Infrastructure team, helped build and maintain
                databases
              </li>
              <li>
                Added tools to scrub PII when cloning databases from prod to
                non-prod environments
              </li>
              <li>
                Helped moving from PostgreSQL to DynamoDB for appropriate teams
              </li>
              <li>
                Skills: Python, PostgresSQL, Terraform, AWS Aurora, GCP,
                DynamoDB
              </li>
            </ul>
          </div>
        </div>

        {/* DV Trading */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Algorithmic Trading Software Developer</b> at DV TRADING
              SYSTEMS
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Jun 2020 - Apr 2021, Toronto</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Helped write code for internal trading platform. Added features
                to help execute scheduled trades
              </li>
              <li>Skills: C#</li>
            </ul>
          </div>
        </div>

        {/* Scribd */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Internal Tools Developer</b> at SCRIBD INC
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Feb 2018 - Jun 2019, Toronto</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Wrote tooling integrate our repos with Sentry.io to make alerts
                immediate and actionable
              </li>
              <li>
                Helped write and maintain internal tools pages and mobile APIs
              </li>
              <li>Skills: Ruby on Rails, Python, React</li>
            </ul>
          </div>
        </div>

        {/* Bloomberg LP */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Financial Software Developer</b> at BLOOMBERG L.P.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Aug 2016 - Sep 2017, London</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Developed a high performance, highly avaiable file storage
                service for Bloomberg internal clients
              </li>
              <li>Skills: C++, Lua, Python</li>
            </ul>
          </div>
        </div>

        {/* Bloomberg LP (Intern) */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Financial Software Developer (Intern)</b> at BLOOMBERG L.P.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Sep 2015 - Dec 2015, New York</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Created tools to encrypt messages sent via Instant Bloomberg
                chat
              </li>
              <li>Skills: C++, Encryption techniques</li>
            </ul>
          </div>
        </div>

        {/* Amazon.com, Inc. (Intern) */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Software Development Engineer (Intern)</b> at AMAZON.COM, INC
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Jan 2015 - Apr 2015, Seattle</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Constructed a website to allow for automated testing of mobile
                apps on test devices
              </li>
              <li>
                Enhanced user feedback with the help of data visualizations of
                test results
              </li>
              <li>
                Skills: JavaScript, Java, AngularJS, D3.js, CanvasJS, Full-stack
                development, Automated testing
              </li>
            </ul>
          </div>
        </div>

        {/* Expedia, Inc. (Intern) */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Software Developer (Intern)</b> at EXPEDIA, INC.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">May 2014 - Aug 2014, Montreal</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Designed and developed the UI for Expedia Inc.'s internal
                mapping tool ensuring strong optimization
              </li>
              <li>
                Added features such as keyboard shortcuts, saving Google maps
                search history and map filters
              </li>
              <li>
                Skills: JavaScript, Java, AngularJS, Thucydides, Jasmine,
                jQuery, Google maps API, Solr, Agile
              </li>
            </ul>
          </div>
        </div>

        {/* Blackberry (Intern) */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Automated Test Developer (Intern)</b> at BLACKBERRY
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Sep 2013 - Dec 2013, Waterloo</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Developed automated API tests in C/C++ for BB Native
                Applications using Google Test Framework
              </li>
              <li>
                Conducted gap analysis on existing test suites. Reported,
                tracked and verified bugs in the API
              </li>
              <li>
                Skills: Test automation, C, C++, Google Test Framework, Python
              </li>
            </ul>
          </div>
        </div>

        {/* Blackberry (Intern) */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Software Developer (Intern)</b> at BLACKBERRY
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Jan 2013 - Apr 2013, Waterloo</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Created scripts necessary to automate and improve the build
                process. Investigated build failures
              </li>
              <li>Skills: Build automation, Continuous integration, Python</li>
            </ul>
          </div>
        </div>

        {/* Nexj Systems Inc. (Intern) */}
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-sm-12">
            <p>
              <b>Quality Assurance Analyst (Intern)</b> at NEXJ SYSTEMS INC.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Apr 2012 - Aug 2012, Toronto</font>
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul>
              <li>
                Created scripts necessary to automate and improve the build
                process. Investigated build failures
              </li>
              <li>Skills: Build automation, Continuous integration, Python</li>
            </ul>
          </div>
        </div>

        <hr />
        {/* END OF Professional Experience */}

        {/* START OF Education */}
        <h4>Education</h4>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-lg-9 col-md-6 col-md-12">
            <p>
              <b>Bachelor of Software Engineering</b> at UNIVERSITY OF WATERLOO
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 text-sm-start text-md-end">
            <i>
              <font color="#808080">Aug 2011 - Apr 2016, Waterloo</font>
            </i>
          </div>
        </div>
        <hr />
        {/* END OF Education */}
      </div>
    </>
  );
};

export default Resume;
